import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

var slider = new KeenSlider(
    '#slider',
    {
     //loop: true,
      defaultAnimation: {
        duration: 3000,
        easing: easeInOutQuad
      },
    },
    [
        (slider) => {
          let timeout
          let mouseOver = false
          let wrapper, arrowLeft, arrowRight
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 4000)
          }
          function markup(remove) {
            wrapperMarkup(remove)
            arrowMarkup(remove)
          }
          function wrapperMarkup(remove) {
            if (remove) {
              var parent = wrapper.parentNode
              while (wrapper.firstChild)
                parent.insertBefore(wrapper.firstChild, wrapper)
              removeElement(wrapper)
              return
            }
            wrapper = createDiv("navigation-wrapper")
            slider.container.parentNode.appendChild(wrapper)
            wrapper.appendChild(slider.container)
          }

          function createDiv(className) {
            var div = document.createElement("div")
            var classNames = className.split(" ")
            classNames.forEach((name) => div.classList.add(name))
            return div
          }

          function arrowMarkup(remove) {
            if (remove) {
              removeElement(arrowLeft)
              removeElement(arrowRight)
              return
            }
            arrowLeft = createDiv("arrow arrow--left")
            arrowLeft.addEventListener("click", () => slider.prev())
            arrowRight = createDiv("arrow arrow--right")
            arrowRight.addEventListener("click", () => slider.next())
        
            wrapper.appendChild(arrowLeft)
            wrapper.appendChild(arrowRight)
          }

          function updateClasses() {
            var slide = slider.track.details.rel
            slide === 0
              ? arrowLeft.classList.add("arrow--disabled")
              : arrowLeft.classList.remove("arrow--disabled")
            slide === slider.track.details.slides.length - 1
              ? arrowRight.classList.add("arrow--disabled")
              : arrowRight.classList.remove("arrow--disabled")
          }

          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            markup()
            updateClasses()
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
          slider.on("slideChanged", () => {
            updateClasses()
          })
          slider.on("optionsChanged", () => {
            console.log(2)
            markup(true)
            markup()
            updateClasses()
          })
          slider.on("destroyed", () => {
            markup(true)
          })
          }
    ]
  )

 var easeInOutQuad = (x)=>{
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2
}

//JE!